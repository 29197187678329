$forMobile: "true";@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.blacklistContactInfo {
    margin-bottom: 60px;

    &__mobile {
        .headline {
            margin-bottom: 15px;
        }

        .phoneContact {
            justify-content: center;
            margin-bottom: 15px;
        }

        .backlink {
            border-top: 0;
            padding-top: 0;
            text-align: center;
        }
    }
}

.headline {
    font-size: 16px;
    margin-bottom: 30px;
}

.phoneContact {
    display: flex;
    margin-bottom: 60px;
}

.phoneIcon {
    color: $blueFire;
    font-size: 20px;
    margin-right: 8px;
}

.phoneNumber {
    color: $blueFire;
    font-size: 20px;
}

.openingHours {
    font-size: 16px;
}

.backlink {
    font-size: 16px;
    padding-top: 15px;
    border-top: 1px solid $plainGrey;
}
